import {
  SET_ERRORS,
  LOADING_UI,
  CLEAR_ERRORS,
  GET_CURRENCY,
  GET_DONATIONS,
  GET_LATEST,
  GET_LEADERBOARD,
  GET_TOTAL_EXPENDITURE,
  GET_PLATES,
  GET_RECEIPT,
  GET_EXPENDITURES,
  CREATE_EXPENDITURE,
  CLEAR_EXPENDITURE,
  SENDING_MAIL,
  SENT_MAIL,
  GET_ONHOLD,
  GET_TOTAL_EXPENDED_USD,
} from '../types';

const initialState = {
  loading: false,
  loadingOnhold: true,
  loadingLatest: true,
  loadingDonations: true,
  loadingLeaderboard: true,
  loadingTotalExpenditure: true,
  loadingPlates: true,
  loadingAllExpenditures: true,
  loadingTotalExpendedUsd: true,
  errors: null,
  allExpenditures: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        updating: false,
        errors: null,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };

    case GET_CURRENCY:
      return {
        ...state,
        price: action.payload,
        loading: false,
      };
    case GET_DONATIONS:
      return {
        ...state,
        donations: action.payload,
        loadingDonations: false,
      };
    case GET_LATEST:
      return {
        ...state,
        latest: action.payload,
        loadingLatest: false,
      };
    case GET_LEADERBOARD:
      return {
        ...state,
        leaderboard: action.payload,
        loadingLeaderboard: false,
      };
    case GET_TOTAL_EXPENDITURE:
      return {
        ...state,
        expenditures: action.payload,
        loadingTotalExpenditure: false,
      };
    case GET_TOTAL_EXPENDED_USD:
      return {
        ...state,
        totalExpendedUsd: action.payload,
        loadingTotalExpendedUsd: false,
      };
    case GET_PLATES:
      return {
        ...state,
        plates: action.payload,
        loadingPlates: false,
      };
    case GET_RECEIPT:
      return {
        ...state,
        receipt: action.payload,
        loading: false,
      };
    case CREATE_EXPENDITURE:
      return {
        ...state,
        expenditure: action.payload,
        loading: false,
      };
    case CLEAR_EXPENDITURE:
      return {
        ...state,
        expenditure: '',
        loading: false,
      };
    case GET_EXPENDITURES:
      return {
        ...state,
        allExpenditures: action.payload,
        loadingAllExpenditures: false,
      };
    case SENDING_MAIL:
      return {
        ...state,
        sending: true,
      };
    case SENT_MAIL:
      return {
        ...state,
        mail: action.payload,
        sending: false,
      };
    case GET_ONHOLD:
      return {
        ...state,
        onhold: action.payload,
        loadingOnhold: false,
      };

    default:
      return state;
  }
}

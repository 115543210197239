import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import YouTube from 'react-youtube';

class About extends Component {
  state = {
    //units: null
  };

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  onVidPlay = (vidName) => {
    try {
      window.snag.events.create({
        eventName: 'playVideo',
        category: 'behavior',
        action: 'play',
        label: vidName,
      });
    } catch (e) {
      console.log('onVidPlay error:', e);
    }
  };

  render() {
    const opts = {
      height: '220',
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    };

    return (
      <div>
        <div className="mainContent">
          <div className="centerHeader">
            <h2>
              <span id="yellow">About Standard Charity</span>
            </h2>
            <div className="howItWorksvideo">
              <YouTube
                videoId="BDgY7-fxO4E"
                opts={opts}
                onReady={this._onReady}
                onPlay={() => this.onVidPlay('about-us')}
              />
              {/*  <img src="/images/videoHolder.png" /> */}
            </div>
          </div>
          <div className="bodyText">
            <p>
              I’ve always wanted to contribute, but I wanted to do it in my way
              and on my terms. Technology excites me. Making a difference in
              someone’s life excites me. Creating excites me. I decided to do
              something with the time that I was left with during the Covid 19
              shutdown. I started researching blockchain solutions and launched
              the development of an app that I hope brings true transparency
              into the marketplace.
            </p>
            <p>
              Standard Charity was an opportunity for me to realize my need for
              contribution while working towards a solution for a larger
              problem, trust in charity. Over 60% of people don’t trust
              charities. If you’re reading this, you’re probably one of the 60%.
              For years I told myself that I would move into tech, that I would
              build something magnificent. Version 1 of this launch gets me out
              into the real world, doing something I’m proud of. I thank you for
              taking the time to read about this charity, and for the honor of
              your consideration. If you donate, all of your money will be
              tracked and visible to you, and anyone who cares to look, at all
              times. That’s the Standard I believe every charity and foundation
              should operate by.
            </p>
            <p>
              If you have interest in working together, donating in dollars
              (right now only “Ether”works with the contract), or have ideas for
              how to improve the operation, please reach out to me directly via
              email and if you’d like to be kept up to date with what’s
              happening please sign up for the charity newsletter through the
              contact us page.{' '}
            </p>
            <p>
              Gill Chowdhury | Founder E:{' '}
              <a
                style={{ color: '#c89b0e', textDecoration: 'underline' }}
                href="mailto:Gill@StandardCharity.Org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gill@StandardCharity.Org
              </a>
            </p>
          </div>
          <div className="socialGrid" style={{ marginTop: '30px' }}>
            <div>
              <a
                href="https://www.facebook.com/Standardcharity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_fb.svg" alt="Facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/standardcharity/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_inst.svg" alt="Instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <img src="images/social_yt.svg" alt="YouTube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const user = users[id];

  return {
    state: state,
    UI: state.UI,
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(About);
///////

import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import superagent from 'superagent';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { SizeMe } from 'react-sizeme';

import Config from '../config';

const config = Config[Config.env];

class TransactionHistoryRow extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      expendedDonationsLoading: false,
      expendedDonations: [],
    };
  }

  fetchExpendedDonations = async () => {
    const { expenditure } = this.props;

    this.setState({
      expendedDonationsLoading: true,
      expendedDonations: [],
    });

    try {
      const res = await superagent.get(
        `${config.backend.api.url}expendedDonations/all?page=1&pageSize=10000&expenditureNumber=${expenditure.expenditureNumber}`
      );

      this.setState({
        expendedDonations:
          res &&
          res.body &&
          res.body.payload &&
          res.body.payload.expendedDonations
            ? res.body.payload.expendedDonations
            : [],
      });
    } catch (e) {
      console.log('fetchExpendedDonations error:', e);
    }

    this.setState({
      expendedDonationsLoading: false,
    });
  };

  render() {
    const { isOpen, expendedDonationsLoading, expendedDonations } = this.state;

    const { expenditure } = this.props;

    if (!expenditure) {
      return null;
    }

    return (
      <div className="transactionItemWrapper">
        <div>
          <SizeMe>
            {({ size }) => (
              <Vimeo
                video={`https://vimeo.com/standardcharity/${expenditure.videoHash}`}
                width={size.width}
              />
            )}
          </SizeMe>
        </div>

        <div className="transactionItem">
          <div
            id="icon"
            onClick={() => {
              this.setState({
                isOpen: !isOpen,
              });

              this.fetchExpendedDonations();
            }}
          >
            <img
              height={27}
              src={`/images/chevron_${isOpen ? 'up' : 'down'}.svg`}
              alt="some alt"
            />
          </div>

          <div id="amount">
            {expenditure.platesDeployed} plates of food for{' '}
            {Number(expenditure.valueExpendedETH / 1e18).toFixed(3)} ETH (
            {(expenditure.valueExpendedUSD / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            )
          </div>
        </div>

        {isOpen && (
          <div style={{ paddingTop: 15, fontSize: 14, color: '#f0b119' }}>
            <p>
              Expenditure created on{' '}
              {DateTime.fromSeconds(expenditure.timestamp).toLocaleString(
                DateTime.DATE_MED
              )}
              .
            </p>

            <p>
              Using{' '}
              <a
                href="https://github.com/Cyan4973/xxHash"
                target="_blank"
                rel="noopener noreferrer"
              >
                xxHash
              </a>
              , the hash of the PDF file is {expenditure.receiptHash}, and the
              hash of the video file is {expenditure.videoHash}.
            </p>

            <p>
              View the receipt for this expenditure{' '}
              <a
                href={`https://assets.standardcharity.org/receipts/${expenditure.receiptHash}.pdf`}
              >
                here
              </a>
              .
            </p>

            <p>
              Owners of these addresses made this charitable expenditure
              possible:
            </p>

            <div>
              {expendedDonationsLoading ? (
                <div className="loaderHolder">
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div>
                  {expendedDonations.length === 0 ? (
                    <div>We could not find the addresses</div>
                  ) : (
                    <ul>
                      {uniqBy(expendedDonations, 'donator').map(
                        (expendedDonation) => (
                          <li
                            style={{ fontSize: 12 }}
                            key={expendedDonation.donator}
                          >
                            {expendedDonation.donator.slice(0, 25)}...
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TransactionHistoryRow;

import React from 'react';
import { withRouter } from 'react-router-dom';

import Config from '../config';

class PerPage extends React.Component {
  componentDidMount() {
    this.onPageview();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onPageview();
    }
  }

  onPageview = () => {
    try {
      window.snag.pageviews.create();

      this.setPageTimers();
    } catch (e) {
      console.log('Pageview event error:', e);
    }
  };

  setPageTimers = () => {
    if (Config.env !== 'production') {
      return;
    }

    const timesSeconds = [5, 15, 30, 60, 120, 180];

    try {
      if (this.pageTimers && Array.isArray(this.pageTimers)) {
        this.pageTimers.map((pageTimer) => clearTimeout(pageTimer));
      }

      this.pageTimers = [];

      timesSeconds.map((time) =>
        this.pageTimers.push(
          setTimeout(() => {
            window.snag.events.create({
              eventName: 'timeSpentOnPage',
              category: 'behavior',
              action: `${time} seconds`,
              label: window.location.pathname,
            });
          }, time * 1000)
        )
      );
    } catch (e) {
      console.log('setPageTimers error:', e);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(PerPage);

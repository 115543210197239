import React, { Component } from 'react';
import gsap from 'gsap';

class FoxLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //units: null
    };
  }
  //componentWillMount() {}

  componentDidMount() {
    gsap.to(document.getElementById('foxLoader'), {
      scale: 1.5,
      duration: 1,
      delay: 0,
      yoyo: true,
      ease: 'power3.out',
      repeat: 500,
    });
  }

  render() {
    return (
      <div id="foxLoader">
        <img src="/images/metamask.svg" alt="MetaMask" />
      </div>
    );
  }
}

FoxLoader.propTypes = {};

export default FoxLoader;

// ui reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_WALLET = 'GET_WALLET';
export const GET_DONATIONS = 'GET_DONATIONS';
export const GET_LATEST = 'GET_LATEST';
export const GET_LEADERBOARD = 'GET_LEADERBOARD';
export const GET_TOTAL_EXPENDITURE = 'GET_TOTAL_EXPENDITURE';
export const GET_PLATES = 'GET_PLATES';
export const GET_RECEIPT = 'GET_RECEIPT';
export const GET_EXPENDITURES = 'GET_EXPENDITURES';
export const CREATE_EXPENDITURE = 'CREATE_EXPENDITURE';
export const CLEAR_EXPENDITURE = 'CLEAR_EXPENDITURE';
export const CLEAR_WALLET = 'CLEAR_WALLET';
export const GET_TOTAL_EXPENDED_USD = 'GET_TOTAL_EXPENDED_USD';

export const SENDING_MAIL = 'SENDING_MAIL';
export const SENT_MAIL = 'SENT_MAIL';
export const GET_ONHOLD = 'GET_ONHOLD';

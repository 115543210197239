import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import xxhash from 'xxhashjs';

import EthInput from '../components/EthInput';
import TextInput from '../components/TextInput';
import FoxLoader from '../components/FoxLoader';
import { Redirect } from 'react-router-dom';
import Button from '../components/Button';
import { setErrors } from '../redux/actions/actions';

class Receive extends Component {
  state = {};

  onSubmit = async (e, name) => {
    try {
      this.props.setInput({
        name: `${name}Hash`,
        val: undefined,
      });

      this.props.setErrors(undefined);

      if (name === 'pdf') {
        if (e.target.files[0].type !== 'application/pdf') {
          return this.props.setErrors('The file type must be PDF.');
        }

        this.props.setInput({
          name: 'receiptPdfFile',
          val: e.target.files[0],
        });

        // this.props.setInput({
        //   name: 'receiptPdfBlob',
        //   val: new Blob([e.target.files[0]], {
        //     type: 'application/pdf',
        //   }),
        // });
      }

      const hash = await this.getHash(e.target.files[0]);

      if (!hash) {
        return this.props.setErrors(`Could not get ${name} hash.`);
      }

      this.props.setInput({
        name: `${name}Hash`,
        val: hash,
      });
    } catch (err) {
      console.log('onSubmit error:', err);

      this.props.setErrors('The file could not be read.');
    }
  };

  onCreateClick = () => {
    this.props.onCreateExpenditure();
  };

  getHash = (file) => {
    return new Promise(async (res) => {
      try {
        const reader = new FileReader();

        reader.onload = async (e) => {
          res(xxhash.h32(e.target.result, 0xcafebabe).toString(16));
        };

        reader.readAsArrayBuffer(file);
      } catch (e) {
        console.log('getHash error:', e);

        res(null);
      }
    });
  };

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    const {
      setExpenditureAmount,
      setInput,
      btnPress,
      price,
      wallet,
      synced,
      fields,
      UI: { loading, expenditure, errors },
    } = this.props;

    return (
      <div className="mainContent">
        {!synced ||
        (wallet &&
          wallet.account &&
          wallet.account.wallet !== wallet.account.standardWallet) ? (
          <Redirect to="/" />
        ) : null}
        <div className="receiveText">
          <EthInput
            price={price && price}
            btnPress={btnPress}
            heading={'Amount requested'}
            setAmount={setExpenditureAmount}
          />
        </div>
        <div>
          <TextInput
            heading={'Number of plates'}
            name="plates"
            setInput={setInput}
            type="number"
          />
        </div>
        {errors && errors.message ? (
          <div className="errorMessage">
            <img src="images/alert.svg" alt="Alert" />

            <div>{errors.message}</div>
          </div>
        ) : null}
        {expenditure && !expenditure.ok ? (
          <div className="errorMessage">
            <img src="images/alert.svg" alt="Alert" />

            <div>{expenditure.error.message}</div>
          </div>
        ) : null}
        <div className="mainContent">
          <div className="centerHeader" style={{ paddingTop: '10px' }}>
            {loading ? (
              <h2>
                <span id="gray">Creating expenditure</span>
                <div className="loadingFox">
                  <FoxLoader />
                </div>
              </h2>
            ) : (
              <>
                {expenditure && expenditure.ok ? (
                  <>
                    <span id="green">Expenditure created!</span>
                    <div className="loadingFox">
                      <img src="/images/tick.svg" alt="Tick" />
                    </div>
                  </>
                ) : (
                  <>
                    {/* <label htmlFor="upload-button">
                      <div className="pdfBtn">
                        <img src="/images/pdfBtn.svg" alt="PDF Upload" />
                      </div>
                    </label>
                    <input
                      type="file"
                      id="upload-button"
                      name="file"
                      onChange={this.onSubmitFile}
                      style={{ display: 'none' }}
                    /> */}

                    <label htmlFor="upload-pdf-button">
                      <Button text="Add receipt PDF" />
                    </label>
                    <input
                      type="file"
                      id="upload-pdf-button"
                      name="file"
                      onChange={(e) => this.onSubmit(e, 'pdf')}
                      style={{ display: 'none' }}
                    />

                    <label htmlFor="upload-video-button">
                      <Button text="Add video" disabled={!fields.pdfHash} />
                    </label>
                    <input
                      type="file"
                      id="upload-video-button"
                      name="file"
                      onChange={(e) => this.onSubmit(e, 'video')}
                      style={{ display: 'none' }}
                      disabled={!fields.pdfHash}
                    />

                    {fields.videoHash && (
                      <span id="gray" style={{ textAlign: 'center' }}>
                        The hash of the video, which should be used for the
                        video's link in Vimeo, is: {fields.videoHash}
                      </span>
                    )}

                    <Button
                      text="Create expenditure"
                      disabled={!fields.pdfHash || !fields.videoHash}
                      onClick={this.onCreateClick}
                    />
                  </>
                )}
              </>
            )}{' '}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.wallet,
    price: state.UI.price,
    UI: state.UI,
  };
};

const mapActionsToProps = (dispatch) => ({
  setErrors: (errors) => dispatch(setErrors(errors)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(Receive);

import React, { Component } from 'react';

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //units: null
    };
  }

  handleChange = (e) => {
    this.setState({ name: e.target.name, val: e.target.value }, () => {
      this.props.setInput(this.state);
    });
  };

  render() {
    return (
      <div>
        <div className="centerHeader">
          <h2>
            <span id="yellow">{this.props.heading}</span>
          </h2>
        </div>
        <div
          style={{ marginBottom: this.props.gap }}
          className={`fieldBack ${
            this.props.small ? 'smallField' : 'bigField'
          }`}
        >
          <input
            onChange={this.handleChange}
            name={this.props.name}
            type={this.props.type}
          />
        </div>
      </div>
    );
  }
}

TextInput.propTypes = {};

export default TextInput;

import React, { Component } from 'react';
import gsap from 'gsap';
import { withRouter } from 'react-router';

let hamburgerOpen = {
  opacity: 1,
  rotation: 0,
  scale: 1,
  duration: 0.5,
  display: 'inherit',
  ease: 'power3.out',
};
let hamburgerClose = {
  rotation: 360,
  opacity: 0,
  duration: 0.5,
  scale: 0,
  display: 'hidden',
  ease: 'power3.out',
};
let navOpen = {
  x: -250,
  duration: 0.5,
  display: 'inherit',
  ease: 'power3.out',
};
let navClose = {
  x: 0,
  duration: 0.5,
  display: 'hidden',
  ease: 'power3.out',
};
class NavBar extends Component {
  state = {
    //units: null
    navOpen: false,
  };

  menuSwitch = (route) => {
    this.props.menuSwitch(route, this.props.history);
  };

  menuHamburger = (route) => {
    if (typeof route === 'object') {
      try {
        window.snag.events.create({
          eventName: 'navigation',
          category: 'behavior',
          action: 'click',
          label: 'hamburger',
        });
      } catch (e) {
        console.log('hamburger click event error:', e);
      }
    }

    this.state.navOpen
      ? (navOpen = {
          ...navOpen,
          onComplete: this.menuSwitch,
          onCompleteParams: [route],
        })
      : (navOpen.onComplete = '');

    if (this.state.navOpen) {
      gsap.to(document.getElementById('overlay'), {
        display: 'block',
        duration: 0.5,
        opacity: 1,
        delay: 0,
        yoyo: true,
        repeat: 1,
      });
    }
    gsap.fromTo(
      document.getElementById('hamburger'),
      !this.state.navOpen ? hamburgerOpen : hamburgerClose,
      !this.state.navOpen ? hamburgerClose : hamburgerOpen
    );
    gsap.fromTo(
      document.getElementById('closebtn'),
      !this.state.navOpen ? hamburgerClose : hamburgerOpen,
      !this.state.navOpen ? hamburgerOpen : hamburgerClose
    );

    gsap.fromTo(
      document.getElementById('sidenav'),
      !this.state.navOpen ? navOpen : navClose,
      !this.state.navOpen ? navClose : navOpen
    );

    this.setState({ navOpen: !this.state.navOpen ? true : false });
  };

  render() {
    const { synced, wallet, donations, metamaskOpen } = this.props;

    return (
      <div>
        <div className="hamburger" onClick={this.menuHamburger}>
          <img id="closebtn" src="/images/closeBtn.svg" alt="Close" />
          <img id="hamburger" src="/images/hamburger.svg" alt="Menu" />
        </div>
        <div className="sideNavContainer" id="sidenav">
          {synced ? (
            <>
              <div
                className="btn_donate"
                id="yellow"
                onClick={() => {
                  this.menuHamburger('/donate');
                }}
              >
                <img src="/images/ethIcon.svg" alt="Ethereum" />
                Donate
              </div>
              <div className="donationText">
                <p>Your donations to date:</p>
                <p id="bigPrice">
                  $
                  {
                    donations && donations.totalExpendedUSD
                    /*  ethToDollar(
                      Number(
                        donations.TotalDonations / 1000000000000000000
                      ).toFixed(2)
                    ) */
                  }
                </p>
                <p>Pending donations:</p>
                <p id="smallPrice">
                  {donations && donations.TotalPending / 1000000000000000000}
                  ETH
                </p>
              </div>
            </>
          ) : (
            <div className="splashLogo" id="splashlogo">
              <img src="/images/logo.png" alt="logo" />
            </div>
          )}
          <div className="navButtons">
            <div className="navButton" onClick={() => this.menuHamburger('/')}>
              <img src="/images/navIcon_home.svg" alt="Home" />
              <h3>Home</h3>
            </div>
            <div
              className="navButton"
              onClick={() => this.menuHamburger('/leaderboard')}
            >
              <img src="/images/navIcon_leaderboard.svg" alt="Leaderboard" />
              <h3>Leaderboard</h3>
            </div>

            <div
              className="navButton"
              onClick={() => this.menuHamburger('/transactionHistory')}
            >
              <img src="/images/navIcon_receipt.svg" alt="Receipt" />
              <h3>Transaction history</h3>
            </div>

            {synced ? (
              <div
                className="navButton"
                onClick={() => this.menuHamburger('/receipt')}
              >
                <img src="/images/navIcon_receipt.svg" alt="Receipt" />
                <h3>Request receipt</h3>
              </div>
            ) : null}
            <div
              className="navButton"
              onClick={() => this.menuHamburger('/howitworks')}
            >
              <img src="/images/navIcon_info.svg" alt="Info" />
              <h3>How it works</h3>
            </div>
            <div
              className="navButton"
              onClick={() => this.menuHamburger('/about')}
            >
              <img src="/images/navIcon_about.svg" alt="About" />
              <h3>About</h3>
            </div>
            {synced &&
            wallet &&
            wallet.account &&
            wallet.account.wallet === wallet.account.standardWallet ? (
              <div
                className="navButton"
                onClick={() => this.menuHamburger('/receive')}
              >
                <img src="/images/navIcon_admin.svg" alt="Admin" />
                <h3>Receive payment</h3>
              </div>
            ) : null}
            <div
              className="navButton"
              onClick={() => this.menuHamburger('/contact')}
            >
              <img src="/images/navIcon_admin.svg" alt="Admin" />
              <h3>Subscribe</h3>
            </div>
            <div
              className="navFooter"
              onClick={() => this.menuHamburger('/privacy')}
              style={{ marginTop: '30px', marginBottom: '30px' }}
            >
              Privacy policy
            </div>
          </div>
        </div>
        <div className="topNavContainer">
          <div></div>

          <div className="appTitle">
            <div style={{ marginRight: '10px' }}>
              <img src="/images/appTitle.png" alt="Standard Charity" />
            </div>
            <div>Standard Charity</div>
          </div>
          <div
            className="walletSync"
            onClick={() =>
              metamaskOpen().then((res) => {
                console.log('metamask open res:', res);
              })
            }
          >
            <img
              src={
                this.props.synced
                  ? '/images/walletSynced.svg'
                  : '/images/walletUnsynced.svg'
              }
              alt="Wallet"
            />
          </div>
        </div>
        <div className="colorOverlay" id="overlay"></div>
      </div>
    );
  }
}

export default withRouter(NavBar);

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import superagent from 'superagent';

import Home from './pages/Home';
import Leaderboard from './pages/Leaderboard';
import Donate from './pages/Donate';
import Receipt from './pages/Receipt';
import HowItWorks from './pages/HowItWorks';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Web3 from 'web3';
import Receive from './pages/Receive';
import { createBrowserHistory } from 'history';
import NavBar from './components/NavBar';
import { ContractABI } from './contracts/ContractABI';
import gsap from 'gsap';
import { saveAs } from 'file-saver';
import {
  getCurrency,
  getWallet,
  getDonations,
  getLatest,
  getLeaderboard,
  getTotalExpenditure,
  getPlates,
  getReceipt,
  getExpenditures,
  createExpenditure,
  loadingUI,
  setErrors,
  sendMail,
  getContractBalance,
  clearWallet,
  getTotalExpendedUsd,
} from './redux/actions/actions';
import './index.css';
import Config from './config';
import TransactionHistory from './pages/TransactionHistory';
import PerPage from './components/PerPage';

const config = Config[Config.env];

const myHistory = createBrowserHistory();

const standardWallet = config.admin.wallet.address;
const contractAddress = config.contracts.standardCharity.address;

const tagManagerArgs = {
  gtmId: 'GTM-WKKFLHM',
};

TagManager.initialize(tagManagerArgs);

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skipped: false,
      splashShow: false,
      synced: false,
      walletChecked: false,
      status: '',
      fields: {},
      dateRange: {},
      walletCheck: false,
    };
    this.requestReceipt = this.requestReceipt.bind(this);
    this.emailSender = this.emailSender.bind(this);

    this.checkBrowser = this.checkBrowser.bind(this);
  }

  btnPress = (e) => {
    gsap.to(e.target, {
      duration: 0.1,
      scale: 0.9,
      delay: 0,
      yoyo: true,
      ease: 'power3.out',
      repeat: 1,
    });
  };

  // For donations only
  setAmount = (amount, currency, conversion) => {
    this.setState({ amount, currency, conversion }, console.log(this.state));
  };
  setExpenditureAmount = (amount, currency, conversion) => {
    this.setState({ expenditureAmount: { amount, currency, conversion } });
  };
  setInput = (inp) => {
    this.setState({ fields: { ...this.state.fields, [inp.name]: inp.val } });
  };

  menuSwitch = (route, history) => {
    this.loadBlockChainData();

    this.resetStatus();

    if (route) {
      history.push(route);
    }

    try {
      window.snag.events.create({
        eventName: 'navigation',
        category: 'behavior',
        action: 'click',
        label: route || 'hamburger',
      });
    } catch (e) {
      console.log('navClickEvent error:', e);
    }
  };

  formatDollarsToCents = (value) => {
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  };

  async emailSender() {
    await this.props.sendMail(this.state.fields);
  }

  onCreateExpenditure = async () => {
    try {
      if (!this.state.expenditureAmount) {
        return this.props.setErrors("You haven't set an amount");
      }

      if (!this.state.fields.plates) {
        return this.props.setErrors("You haven't set a number of plates");
      }

      if (!this.state.fields.receiptPdfFile) {
        return this.props.setErrors('Please provide a receipt PDF');
      }

      if (!this.state.fields.pdfHash) {
        return this.props.setErrors('The receipt PDF could not be hashed');
      }

      if (!this.state.fields.videoHash) {
        return this.props.setErrors('Please provide a video file');
      }

      this.props.loadingUI();
      const web3 = window.web3;
      const file = new FormData();

      file.append('name', this.state.fields.receiptPdfFile.name);

      const filey = new Blob([this.state.fields.receiptPdfFile], {
        type: 'application/pdf',
      });

      file.append('file', this.state.fields.receiptPdfFile);

      let expenditureAmountDollar;

      const fileData = {};

      fileData.file = filey;
      fileData.fileName = this.state.fields.receiptPdfFile.name;

      if (this.state.expenditureAmount.currency === 'dollar') {
        expenditureAmountDollar = this.formatDollarsToCents(
          this.state.expenditureAmount.amount
        );
      } else {
        expenditureAmountDollar = this.formatDollarsToCents(
          this.state.expenditureAmount.conversion
        );
      }

      const message = JSON.stringify({
        platesDeployed: this.state.fields.plates * 10, // denoninated as *10, i.e. 50 = 5.0. The 10 value here represents 1 plate.
        usd: expenditureAmountDollar, // in cents
        pdfHash: this.state.fields.pdfHash,
        videoHash: this.state.fields.videoHash,
      });

      const signature = await web3.eth.personal.sign(
        message,
        this.props.wallet.account.wallet,
        ''
      );

      fileData.message = message;
      fileData.signature = signature;

      const expenditureResult = await this.props.createExpenditure(fileData);

      const vimeoLink = new Blob(
        [
          `${JSON.stringify(
            expenditureResult
          )}\n\nDo not forget to upload the video to Vimeo and to create the custom video URL!`,
        ],
        {
          type: 'text/plain;charset=utf-8',
        }
      );

      saveAs(
        vimeoLink,
        `${
          expenditureResult.payload.videoHash
            ? expenditureResult.payload.videoHash
            : 'error'
        }.txt`
      );
    } catch (e) {
      console.log('Expenditure creation error:', e);

      this.props.setErrors(
        'There was an error while creating the expenditure. Check the console.'
      );
    }
  };

  setDateRange = (dates) => {
    this.setState({
      dateRange: { startDate: dates.start, endDate: dates.end },
    });
  };

  async componentDidMount() {
    const metamaskInstalled =
      typeof window.web3 !== 'undefined' ||
      typeof window.ethereum !== 'undefined';

    this.setState({ metamaskInstalled: metamaskInstalled });

    if (metamaskInstalled) {
      await this.checkBrowser();
      await this.loadBlockChainData();

      window.ethereum.on('accountsChanged', () => {
        this.accountChangeDetect(window.web3.currentProvider.selectedAddress);
      });
    } else {
      console.log('no Metamask');
    }
  }
  async accountChangeDetect(selectedAddress) {
    if (selectedAddress === null && !this.state.walletCheck) {
      this.props.clearWallet();
      this.loadBlockChainData();
      this.setState({ walletCheck: true });
    }

    if (selectedAddress !== null) {
      if (
        this.props.wallet.account.wallet === null ||
        selectedAddress !== this.props.wallet.account.wallet.toLowerCase()
      ) {
        this.loadBlockChainData();
        this.setState({ walletCheck: false });
      }
    }
  }
  async metamaskOpen() {
    window.web3 = new Web3(window.ethereum);
    await window.web3.currentProvider.enable();
  }

  reloadData = () => {
    this.loadBlockChainData();
  };

  resetStatus = () => {
    this.setState({ status: '' });
  };

  async loadBlockChainData() {
    try {
      const web3 = window.web3;

      const accounts = await web3.eth.getAccounts();
      const account = {};
      this.props.getLatest();
      this.props.getLeaderboard();
      this.props.getTotalExpenditure();
      this.props.getPlates();
      this.props.getContractBalance();
      this.props.getExpenditures();
      this.props.getTotalExpendedUsd();

      if (accounts && accounts.length > 0) {
        this.setState({
          synced: true,
        });
        account.wallet = accounts[0];
        const networkId = await web3.eth.net.getId();
        account.networkId = networkId;
        const balance = await web3.eth.getBalance(accounts[0]);

        account.balance = balance / 1e18;
        account.standardWallet = standardWallet;
        account.contractAddress = contractAddress;
        this.props.getWallet(account);
        this.props.getDonations(account);
      } else {
        this.setState({ synced: false });
      }
    } catch (e) {
      console.log('loadBlockChainData error:', e);
    }
  }

  async loadBlockChainDataNoEth() {
    this.props.getLatest();
    this.props.getLeaderboard();
    this.props.getTotalExpenditure();
    this.props.getPlates();
    this.props.getContractBalance();
    this.props.getExpenditures();
    this.props.getTotalExpendedUsd();
  }

  async requestReceipt() {
    const receiptData = {
      name: this.state.fields.ReceiptName,
      startDate: Number(this.state.dateRange.startDate + '000'),
      endDate: Number(this.state.dateRange.endDate + '000'),
      wallet: this.props.wallet.account.wallet,
    };
    const receiptResponse = await this.props.getReceipt(receiptData);
    if (
      receiptResponse &&
      receiptResponse.payload &&
      receiptResponse.payload.url
    ) {
      saveAs(
        receiptResponse.payload.url,
        receiptResponse.payload.url.split('/').pop()
      );
    }
  }

  async checkBrowser() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      return true;
    } else {
      window.alert('Non-Etherum browser detected.');
      return false;
    }
  }

  skipWallet = (amount) => {
    this.setState({ skipped: true });
    this.loadBlockChainDataNoEth();
  };

  ethToDollar = (amount) => {
    return parseFloat(
      (amount * Number(this.props.price)).toFixed(2)
    ).toLocaleString();
  };

  sendTransaction = async () => {
    this.setState({ status: 'sending' });

    const web3 = window.web3;

    var contractInstance = new web3.eth.Contract(ContractABI, contractAddress);

    const donateParams = {
      from: this.props.wallet && this.props.wallet.account.wallet,
      to: contractAddress,
      value:
        this.state.currency === 'eth'
          ? this.state.amount * 1e18
          : this.state.conversion * 1e18,
    };

    const gasAmount = await this.estimateDonateGas(
      contractInstance,
      donateParams
    );

    if (!gasAmount) {
      console.log('Could not get gas amount for tx:', gasAmount);

      return this.setState({ status: 'error' });
    }

    try {
      window.snag.events.create({
        eventName: 'Event',
        category: 'conversion',
        action: `donate`,
      });
    } catch (e) {
      console.log('snag event error:', e);
    }

    const gasPriceRes = await superagent.get(
      `${config.backend.api.url}utils/gasPrice`
    );

    if (!gasPriceRes || !gasPriceRes.body || !gasPriceRes.body.payload) {
      console.log('Could not get gas price from API');

      return this.setState({ status: 'error' });
    }

    const gasPriceObj = gasPriceRes.body.payload;

    const sendDonation = contractInstance.methods
      .donate()
      .send({
        from: this.props.wallet && this.props.wallet.account.wallet,
        to: contractAddress,
        gas: gasAmount,
        ...gasPriceObj,
        type: gasPriceObj.maxFeePerGas ? '0x2' : undefined,
        value:
          this.state.currency === 'eth'
            ? this.state.amount * 1e18
            : this.state.conversion * 1e18,
      })
      .on('transactionHash', (hash) => {
        console.log('stage 1');
        this.setState({ status: 'hash' });
      })
      .on('receipt', (receipt) => {
        console.log('stage 2');
        this.setState({ status: 'receipt' });
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        console.log('stage 3');
        this.setState({ status: 'sent' });
        this.loadBlockChainData();
        sendDonation.removeAllListeners();
      })
      .on('error', (error, receipt) => {
        console.log('stage 4');

        this.setState({ status: 'error' });

        if (receipt) {
          console.error('sendTransaction error:', error);
          console.log('sendTransaction receipt:', receipt);
        }
      });
  };

  estimateDonateGas = (contractInstance, donateParams) => {
    return new Promise((resolve) => {
      try {
        contractInstance.methods
          .donate()
          .estimateGas(donateParams)
          .then((gasAmount) => {
            resolve(gasAmount || null);
          })
          .catch((err) => {
            console.log('estimateGas catch error:', err);

            resolve(null);
          });
      } catch (e) {
        console.log('estimateDonateGas error:', e);

        resolve(null);
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.price) {
      nextProps.getCurrency().then((res) => {
        return { price: res };
      });
    } else {
      return null;
    }

    return null;
  }

  modeToggle = () => {
    this.props.toggleMode();
  };

  render() {
    const { donations } = this.props;
    return (
      <Router history={myHistory}>
        <PerPage />

        <div>
          <div>
            {this.state.synced || this.state.skipped ? (
              <NavBar
                synced={this.state.synced}
                wallet={this.props.wallet}
                ethToDollar={this.ethToDollar}
                donations={donations}
                loadBlockChainData={this.reloadData}
                metamaskOpen={this.metamaskOpen}
                resetStatus={this.resetStatus}
                menuSwitch={this.menuSwitch}
              />
            ) : null}
          </div>

          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Home
                    btnPress={this.btnPress}
                    synced={this.state.synced}
                    metamaskInstalled={this.state.metamaskInstalled}
                    ethToDollar={this.ethToDollar}
                    skipped={this.state.skipped}
                    skipWallet={this.skipWallet}
                    menuSwitch={this.menuSwitch}
                  />
                )}
              />
              <Route
                exact
                path="/leaderboard"
                render={() => <Leaderboard btnPress={this.btnPress} />}
              />
              <Route
                exact
                path="/contact"
                render={() => (
                  <Contact
                    setInput={this.setInput}
                    emailSender={this.emailSender}
                    btnPress={this.btnPress}
                  />
                )}
              />
              <Route
                exact
                path="/donate"
                render={() => (
                  <Donate
                    btnPress={this.btnPress}
                    sendTransaction={this.sendTransaction}
                    status={this.state.status}
                    setAmount={this.setAmount}
                    synced={this.state.synced}
                  />
                )}
              />
              {/*  <Route exact path="/donate" component={Donate} /> */}

              <Route
                exact
                path="/receipt"
                render={() => (
                  <Receipt
                    setInput={this.setInput}
                    setDateRange={this.setDateRange}
                    btnPress={this.btnPress}
                    requestReceipt={this.requestReceipt}
                    synced={this.state.synced}
                  />
                )}
              />
              <Route
                exact
                path="/howitworks"
                render={() => <HowItWorks btnPress={this.btnPress} />}
              />
              <Route
                exact
                path="/privacy"
                render={() => <Privacy btnPress={this.btnPress} />}
              />
              <Route
                exact
                path="/about"
                render={() => <About btnPress={this.btnPress} />}
              />
              <Route
                exact
                path="/receive"
                render={() => (
                  <Receive
                    btnPress={this.btnPress}
                    setExpenditureAmount={this.setExpenditureAmount}
                    setInput={this.setInput}
                    onCreateExpenditure={this.onCreateExpenditure}
                    synced={this.state.synced}
                    fields={this.state.fields}
                  />
                )}
              />
              <Route
                exact
                path="/transactionHistory"
                render={() => <TransactionHistory btnPress={this.btnPress} />}
              />
            </Switch>
            <div className="nav"></div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.wallet,
    price: state.UI.price,
    donations: state.UI.donations,
  };
};

const mapActionsToProps = (dispatch) => ({
  getCurrency: (currency) => dispatch(getCurrency(currency)),
  getWallet: (wallet) => dispatch(getWallet(wallet)),
  getDonations: (donations) => dispatch(getDonations(donations)),
  getLatest: () => dispatch(getLatest()),
  getContractBalance: (bal) => dispatch(getContractBalance(bal)),
  getLeaderboard: () => dispatch(getLeaderboard()),
  getTotalExpenditure: () => dispatch(getTotalExpenditure()),
  getPlates: () => dispatch(getPlates()),
  getReceipt: (receipt) => dispatch(getReceipt(receipt)),
  createExpenditure: (expenditure) => dispatch(createExpenditure(expenditure)),
  loadingUI: (loading) => dispatch(loadingUI(loading)),
  setErrors: (errors) => dispatch(setErrors(errors)),
  sendMail: (emaildata) => dispatch(sendMail(emaildata)),
  clearWallet: () => dispatch(clearWallet()),
  getExpenditures: () => dispatch(getExpenditures()),
  getTotalExpendedUsd: () => dispatch(getTotalExpendedUsd()),
});

export default connect(mapStateToProps, mapActionsToProps)(App);

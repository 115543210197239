import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class HowItWorks extends Component {
  state = {};

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <div>
        <div className="mainContent">
          <div className="howItWorks" id="howitworks">
            <div className="centerHeader">
              <h2>
                <span id="yellow">How it works</span>
              </h2>
            </div>
          </div>
          <div className="bodyText">
            <p>
              Standard Charity operates a “smart contract” that is built on the
              Ethereum network. The protocols of the contract are hard coded in,
              giving the charity only one set of options in order to retrieve
              the funds donated, which are locked in escrow, until the charity
              provides proof of use.
            </p>
            <p>
              The problem that Standard Charity is attempting to solve is that
              of trust, in this case, trust between a donor and a charitable
              organization. We aim to give a donor, as well as anyone who cares
              to look, the ability to track every penny that ever comes into the
              charity, and the exact final destination it is deployed to.
            </p>
            <p>
              You will need a “wallet” to properly access the smart contract
              hard coded into the app. Version 1 was built specifically with
              Metamask being tested. Metamask now lets you browse the web on
              your mobile smart phone in order to interact with blockchain
              “Dapps”
            </p>
            <p>
              Please{' '}
              <a
                style={{ color: '#c89b0e', textDecoration: 'underline' }}
                href="http://metamask.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                download the Metamask browser
              </a>{' '}
              and revisit our site at standardcharity.org to access the donate
              feature.{' '}
            </p>
            <h2>V1.0 addresses the problem as follows:</h2>
            <p>
              All donations are received in Ethereum tokens and are immediately
              locked into escrow. They are locked into escrow on a first come
              first serve basis. Meaning, if donor 1 donates 1 ETH, donor 2
              donates .5 ETH, and donor 3 donates 2 ETH, the contract will
              release funds as called by the admin in the order they arrived.
            </p>
            <p>
              All donations are returned to the donor wallet address if they are
              not deployed within 28 days. This is done so that funds do not
              pile up faster than they can be of service to the public and end
              up sitting in the coffers. Also, so that they don’t end up locked
              in contract in the event the admin becomes incapacitated.
            </p>
            <p>
              To give an example consistent with the 3 donations above, if the
              admin requires funds in the amount of 1.35 ETH the entirety of the
              first donar’s balance will be released to the admin and .35 of the
              second donor’s .5 ETH will be released to the charity leaving
              donor 2 with a balance of .15 ETH in escrow and donor 3 with a
              balance of 2 ETH in escrow.
            </p>
            <p>
              The retrieval of funds DOES NOT reset the 28 day clock. That clock
              starts the moment funds are donated. In the case of donor 2, the
              charity would have 28 days minus the time passed between donation
              and release of funds, to deploy the remaining .15 ETH that is in
              escrow.
            </p>

            <h2>Conversion ETH / USD</h2>
            <p>
              Conversion is calculated at the time that the charity asks for
              funds to be released. Meaning, if you donate 1ETH with a value of
              $400 at the time of donation, it is locked in escrow as 1ETH. If 3
              days later the charity requests $200 worth of ETH per demonstrated
              use, and the value of ETH at that point in time is now 1ETH/$200
              USD, then the donation will be recorded as $200 USD.
            </p>
            <h2>Tax deductibility </h2>
            <p>
              Standard Charity has filed for 501(c) 3 status with the IRS. There
              are no users and there is no central database. Instead the system
              recognizes a “user” by the wallet they sync via Web3. That user is
              able to request from the system, a receipt for any specified
              period of time they choose which will be automatically be produced
              in PDF format for submission to the IRS.
            </p>
            <h2>Problems</h2>
            <p>
              There are problems that version 1 does not address, and did not
              have the resource to address. Part of the goal of Standard Charity
              is to gain awareness and credibility in a community which we hope
              will lead to larger donations to further build out the
              technological functionality, or even hear from people on pathways
              to a solution. Some problems that we know exist are:
            </p>
            <ol>
              <li>
                the release of funds. Because we are first spending and
                distributing the funds before the escrow is released, we are
                required to have that money in advance. This defeats the purpose
                of donations. Founder Gill Chowdhury is starting by providing
                funds up front, and then receiving funds back through donations
                as they are deployed by the charity.
              </li>
              <li>
                Conversion and crypto currency. We are launching only accepting
                ETH and would like to open up use to an average user which would
                entail accepting FIAT currencies on chain. Tackling a solution
                for exchange rate and how to deal with someone who doesn’t have
                experience in crypto, doesn’t have a wallet setup, etc., are
                problems that will need to be solved.
              </li>
              <li>
                API functionality. We are launching with two points of proof for
                the funds deployed. An invoice manually created by the charity
                with any applicable receipts from vendors, as well as a
                corroborating mp4 file both hashed to confirm authenticity are
                the current off chain mechanisms that allow funds to be
                released. A process that further removes trust needed towards
                the authenticity of the video matching the manually created mp4
                will further the goal of the charity.
              </li>
            </ol>
            <h2>Open Source</h2>
            <p>
              The contract is available as an open source repo on github.{' '}
              <a
                style={{ color: '#c89b0e', textDecoration: 'underline' }}
                href="https://github.com/Standard-Charity-Inc/eth-contracts"
                target="_blank"
                rel="noopener noreferrer"
              >
                You can find the public link here.
              </a>
            </p>
            <p>
              If you have ideas about how to improve the functionality of the
              contract, would like to get involved, or know someone that would,
              please email Gill at{' '}
              <a
                style={{ color: '#c89b0e', textDecoration: 'underline' }}
                href="mailto:Gill@StandardCharity.Org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gill@StandardCharity.Org
              </a>
            </p>
          </div>
          <div className="socialGrid" style={{ marginTop: '30px' }}>
            <div>
              <a
                href="https://www.facebook.com/Standardcharity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_fb.svg" alt="Facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/standardcharity/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_inst.svg" alt="Instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <img src="images/social_yt.svg" alt="YouTube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const user = users[id];

  return {
    state: state,
    UI: state.UI,
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(HowItWorks);
///////

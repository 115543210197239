import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import TextInput from '../components/TextInput';
import '../datePicker.css';
import { DateRangePicker } from 'rsuite';
import FoxLoader from '../components/FoxLoader';
class Receipt extends Component {
  state = {};

  onOk = (range) => {
    const dates = {};

    let startDate = Math.floor(new Date(range[0]).getTime() / 1000.0);
    let endDate = Math.floor(new Date(range[1]).getTime() / 1000.0);

    dates.start = startDate;
    dates.end = endDate;

    this.props.setDateRange(dates);
  };

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    const {
      setInput,
      requestReceipt,
      synced,
      UI: { receipt, loading },
    } = this.props;

    return (
      <div>
        <div className="mainContent">
          {!synced ? <Redirect to="/" /> : null}
          <>
            <div style={{ padding: '20px' }}>
              <div className="centerHeader">
                <h2>
                  <span id="yellow">Pull a tax deductible receipt</span>
                </h2>
              </div>
              <div className="paragraphText">
                <p>
                  Receive an IRS compliant form for tax deductible purposes for
                  all donations made during the below date range.
                </p>
              </div>
              <div className="calendarIcon">
                <img src="/images/cal.svg" alt="calendar" />
              </div>
            </div>
            <DateRangePicker
              showOneCalendar
              format="MMM D, YYYY"
              className="calDesign"
              onOk={(e) => this.onOk(e)}
              placeholder={
                <div className="placeHolder_btn">
                  <img src="/images/selectArrows.svg" alt="calendar" />
                  Select Dates
                </div>
              }
            />

            <div style={{ marginTop: '30px' }}>
              <TextInput
                heading={'Name'}
                type="text"
                name="ReceiptName"
                setInput={setInput}
              />
            </div>
            {receipt && !receipt.ok ? (
              <div className="centerHeader">
                <div className="errorMessage">
                  <img src="images/alert.svg" alt="Alert" />

                  <div>{receipt.error.message}</div>
                </div>
              </div>
            ) : null}

            {loading ? (
              <div className="centerHeader" style={{ paddingTop: '10px' }}>
                <h2>
                  <span id="gray">Retrieving receipt</span>
                  <div className="loadingFox">
                    <FoxLoader />
                  </div>
                </h2>
              </div>
            ) : (
              <div
                className="donateButton noSelect"
                style={{ marginTop: '50px' }}
                onClick={requestReceipt}
              >
                Retrieve
              </div>
            )}
          </>
          <div className="socialGrid" style={{ marginTop: '50px' }}>
            <div>
              <a
                href="https://www.facebook.com/Standardcharity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_fb.svg" alt="Facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/standardcharity/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_inst.svg" alt="Instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <img src="images/social_yt.svg" alt="YouTube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const user = users[id];

  return {
    wallet: state.wallet,
    UI: state.UI,
    /*  address: state.wallet.account, */
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(Receipt);
///////

/* eslint-disable array-callback-return */
import axios from 'axios';
import * as superagent from 'superagent';

import {
  GET_CURRENCY,
  GET_WALLET,
  GET_DONATIONS,
  GET_LATEST,
  GET_LEADERBOARD,
  GET_TOTAL_EXPENDITURE,
  GET_TOTAL_EXPENDED_USD,
  GET_PLATES,
  GET_RECEIPT,
  GET_EXPENDITURES,
  LOADING_UI,
  SENDING_MAIL,
  SENT_MAIL,
  CREATE_EXPENDITURE,
  CLEAR_EXPENDITURE,
  SET_ERRORS,
  GET_ONHOLD,
  CLEAR_WALLET,
} from '../types';
import Config from '../../config';

const config = Config[Config.env];

const api = config.backend.api.url;

export function getCurrency() {
  return function (dispatch) {
    return axios
      .get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum'
      )
      .then((res) => {
        dispatch({ type: GET_CURRENCY, payload: res.data[0].current_price });

        return res.data;
      })
      .catch((err) => {
        console.log('Error in getCurrency:', err);

        return err;
      });
  };
}

export function getWallet(account) {
  return function (dispatch) {
    dispatch({
      type: GET_WALLET,
      payload: account,
    });
  };
}

export function getDonations(account) {
  let Value = 0;
  let Expended = 0;
  let Refunded = 0;
  let TotalPending = 0;
  let totalExpendedUSD = 0;
  let DonationTotals = {};

  return function (dispatch) {
    return axios
      .get(api + `donations/all?address=${account.wallet}&page=1&pageSize=100`)
      .then((res) => {
        res.data.payload.donations.map((val) => {
          Value = Number(Value) + Number(val.value);
          Expended = Number(Expended) + Number(val.valueExpendedETH);
          Refunded = Number(Refunded) + Number(val.valueRefundedETH);
          totalExpendedUSD = totalExpendedUSD + val.valueExpendedUSD;
        });

        TotalPending = Value - Expended - Refunded;
        DonationTotals.TotalPending = TotalPending;
        DonationTotals.TotalDonations = Expended;
        DonationTotals.totalExpendedUSD = totalExpendedUSD /= Math.pow(100, 1);

        return DonationTotals;
      })
      .then(() => {
        dispatch({
          type: GET_DONATIONS,
          payload: DonationTotals,
        });
      })
      .catch((err) => {
        console.log('getDonations error:', err);

        return err;
      });
  };
}

export function getExpenditures(startAtTimestamp, endAtTimestamp) {
  return function (dispatch) {
    let requestUrl = `${api}expenditures/all?page=1&pageSize=10000`;

    if (startAtTimestamp) {
      requestUrl += `&startAtTimestamp=${startAtTimestamp}`;
    }

    if (endAtTimestamp) {
      requestUrl += `&endAtTimestamp=${endAtTimestamp}`;
    }

    return axios
      .get(requestUrl)
      .then((res) => {
        dispatch({
          type: GET_EXPENDITURES,
          payload:
            res && res.data && res.data.payload && res.data.payload.expenditures
              ? res.data.payload.expenditures
              : [],
        });
      })
      .catch((err) => {
        console.log('getExpenditures error:', err);

        return err;
      });
  };
}

export function getLatest() {
  return function (dispatch) {
    return axios
      .get(api + 'donations/latest')
      .then((res) => {
        dispatch({
          type: GET_LATEST,
          payload: res.data.payload,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getLatest error:', err);

        return err;
      });
  };
}

export function getContractBalance() {
  return function (dispatch) {
    return axios
      .get(api + 'contract/balance')
      .then((res) => {
        dispatch({
          type: GET_ONHOLD,
          payload: res.data.payload,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getContractBalance error:', err);

        return err;
      });
  };
}
export function getLeaderboard() {
  return function (dispatch) {
    return axios
      .get(
        api +
          'donations/grouped?page=1&pageSize=10&by=donator&sortBy=totalValue'
      )
      .then((res) => {
        dispatch({
          type: GET_LEADERBOARD,
          payload:
            res.data.payload.groupedDonations /* { account, standardWallet } */,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getLeaderboard error:', err);

        return err;
      });
  };
}

export function getPlates() {
  return function (dispatch) {
    return axios
      .get(api + 'expenditures/plates')
      .then((res) => {
        dispatch({
          type: GET_PLATES,
          payload: res.data.payload,
        });

        dispatch({
          type: CLEAR_EXPENDITURE,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getPlates error:', err);

        return err;
      });
  };
}

export function getTotalExpenditure() {
  return function (dispatch) {
    return axios
      .get(api + 'expenditures/totalEth')
      .then((res) => {
        dispatch({
          type: GET_TOTAL_EXPENDITURE,
          payload: res.data.payload,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getTotalExpenditure error:', err);

        return err;
      });
  };
}

export function getTotalExpendedUsd() {
  return function (dispatch) {
    return axios
      .get(api + 'expenditures/totalUsd')
      .then((res) => {
        dispatch({
          type: GET_TOTAL_EXPENDED_USD,
          payload:
            res &&
            res.data &&
            res.data.payload &&
            res.data.payload.totalExpendedUsd
              ? res.data.payload.totalExpendedUsd
              : 0,
        });

        return res.data;
      })
      .catch((err) => {
        console.log('getTotalExpendedUsd error:', err);

        return err;
      });
  };
}

export function createExpenditure(fileData) {
  return async function (dispatch) {
    const res = await superagent
      .post(
        `${api}expenditures/create?message=${fileData.message}&signature=${fileData.signature}`
      )
      .attach('videoAndReceipt', fileData.file);

    dispatch({
      type: CREATE_EXPENDITURE,
      payload: res.body,
    });

    return res.body;
  };
}

export function getReceipt(data) {
  const { startDate, endDate, name, wallet } = data;

  return function (dispatch) {
    if (!name) {
      dispatch({
        type: GET_RECEIPT,
        payload: {
          error: { message: 'Please enter your name' },
        },
      });
    } else {
      dispatch({
        type: LOADING_UI,
      });
      return axios
        .get(
          api +
            'receipts?beginTimestamp=' +
            startDate +
            '&endTimestamp=' +
            endDate +
            '&donorName=' +
            name +
            '&address=' +
            wallet
        )
        .then((res) => {
          dispatch({
            type: GET_RECEIPT,
            payload: res.data,
          });

          return res.data;
        })
        .catch((err) => {
          console.log('getReceipt error:', err);

          dispatch({
            type: GET_RECEIPT,
            payload: err.data,
          });

          return err;
        });
    }
  };
}

export function loadingUI() {
  return function (dispatch) {
    dispatch({
      type: LOADING_UI,
    });
  };
}

export function clearWallet() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_WALLET,
    });
  };
}

export function setErrors(message) {
  return function (dispatch) {
    dispatch({
      type: SET_ERRORS,
      payload: { message: message },
    });
  };
}

export function sendMail(emailData) {
  return async function (dispatch) {
    dispatch({
      type: SENDING_MAIL,
    });

    const res = await superagent
      .post(`${api}marketing/createSubscriber`)
      .send(emailData);

    dispatch({
      type: SENT_MAIL,
      payload: res.body,
    });

    return res.body;
  };
}

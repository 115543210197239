import React from 'react';
import styled from 'styled-components';

export default class Button extends React.Component {
  render() {
    const { onClick, text, disabled } = this.props;

    return (
      <StyledButton onClick={disabled ? null : onClick} disabled={disabled}>
        {text}
      </StyledButton>
    );
  }
}

const StyledButton = styled.div`
  font-size: 1.3em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  color: #0f2060;
  border: #d99f01;
  background-color: #d99f01;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

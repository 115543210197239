import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import EthInput from '../components/EthInput';
import FoxLoader from '../components/FoxLoader';

class Donate extends Component {
  state = {};

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    const {
      account,
      price,
      btnPress,
      sendTransaction,
      status,
      setAmount,
      synced,
    } = this.props;

    return (
      <div>
        <div className="mainContent">
          {!synced ? <Redirect to="/" /> : null}
          <div style={{ padding: '20px' }}>
            <div className="centerHeader">
              <h2>
                From <span id="yellow">your address:</span>
              </h2>

              <span id="key">{account && account.wallet}</span>
            </div>
            <div className="donateWallet">
              <div className="glowBox generic" id="yellow">
                <div id="label">Wallet balance:</div>
                <span id="total">
                  {account && account.balance.toFixed(5) + ' ETH'}
                </span>
                <div id="eth">
                  $
                  {account && price ? (
                    <>
                      {parseFloat(
                        (account.balance * Number(price)).toFixed(2)
                      ).toLocaleString()}{' '}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="downArrow">
            <img src="/images/downArrow.svg" alt="Down" />
          </div>
          <div className="centerHeader">
            {status === 'sending' ? (
              <>
                {' '}
                <h2>
                  <span id="gray">Sending transaction...</span>
                  <div className="loadingFox">
                    <FoxLoader />
                  </div>
                </h2>
              </>
            ) : status === 'hash' ? (
              <>
                <span id="blue">Generating receipt...</span>
                <div className="loadingFox">
                  <FoxLoader />
                </div>
              </>
            ) : status === 'receipt' ? (
              <>
                <span id="yellow">Saving receipt...</span>
                <div className="loadingFox">
                  <FoxLoader />
                </div>
              </>
            ) : status === 'sent' ? (
              <>
                <span id="green">Transaction complete!</span>
                <div className="loadingFox">
                  <img src="/images/tick.svg" alt="Tick" />
                </div>
              </>
            ) : status === 'error' ? (
              <>
                <span id="red">The transaction could not be completed!</span>
                <div className="loadingFox">
                  <img src="/images/Red_x.svg" alt="Close" />
                </div>
              </>
            ) : (
              <>
                <h2>
                  To <span id="yellow">Standard Charity's address</span>
                </h2>
                <span id="keybg">{account && account.contractAddress}</span>
              </>
            )}
          </div>

          <EthInput
            price={price && price}
            btnPress={btnPress}
            heading={'Donation amount'}
            setAmount={setAmount}
          />
          <div
            className="donateButton noSelect"
            onMouseDown={btnPress}
            onClick={sendTransaction}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          >
            Donate
            <img src="/images/btn_donate_arrow.svg" alt="Donate" id="dead" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const user = users[id];

  return {
    wallet: state.wallet,
    price: state.UI.price,
    account: state.wallet.account,
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(Donate);
///////

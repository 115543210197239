import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import TextInput from '../components/TextInput';

class Contact extends Component {
  state = {};

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    const {
      setInput,
      UI: { mail },
    } = this.props;

    return (
      <div>
        <div className="mainContent">
          <div className="centerHeader">
            <h1 style={{ marginTop: '70px', marginBottom: '10px' }}>
              Subscribe
            </h1>
          </div>
          <div className="socialGrid" style={{ marginTop: '10px' }}>
            <div>
              <a
                href="https://www.facebook.com/Standardcharity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_fb.svg" alt="Facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/standardcharity/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_inst.svg" alt="Instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <img src="images/social_yt.svg" alt="YouTube" />
              </a>
            </div>
          </div>
          <div>
            <div>
              <TextInput
                heading={'First Name'}
                name="firstName"
                setInput={setInput}
                type="text"
                small
                gap={25}
              />
            </div>
            <div>
              <TextInput
                heading={'Last Name'}
                name="lastName"
                setInput={setInput}
                type="text"
                small
                gap={25}
              />
            </div>

            <div>
              <TextInput
                heading={'Email'}
                name="email"
                setInput={setInput}
                type="text"
                small
                gap={25}
              />
            </div>
            {/*     <div>
              <TextArea
                heading={"Message"}
                name="message"
                setInput={setInput}
                type="text"
                small
                gap={25}
              />
            </div> */}
            <div style={{ marginBottom: '40px' }}>
              {mail && mail.ok ? (
                <div className="centerHeader">
                  <h2>
                    <span id="yellow">Thank you! Stay tuned!</span>
                  </h2>
                </div>
              ) : mail && mail.error ? (
                <div className="errorMessage">
                  <img src="images/alert.svg" alt="Alert" />

                  <div>{mail.error.message}</div>
                </div>
              ) : null}
              {!mail || (mail && !mail.ok) ? (
                <div
                  className="donateButton noSelect"
                  onMouseDown={this.props.btnPress}
                  onClick={() => this.props.emailSender()}
                >
                  Submit
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const user = users[id];

  return {
    state: state,
    UI: state.UI,
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(Contact);
///////

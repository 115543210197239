import React, { Component } from 'react';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import gsap from 'gsap';
import { withRouter } from 'react-router';

class Home extends Component {
  state = {
    splashShow: false,
    videoShow: true,
    ytReady: false,
    blurbText: '',
  };

  _onReady = (event) => {
    this.setState({ ytReady: true });
  };

  removeVideo = () => {
    this.setState({ videoShow: false });
    localStorage.setItem('videoForget', true);
  };

  onVidPlay = (vidName) => {
    try {
      window.snag.events.create({
        eventName: 'playVideo',
        category: 'behavior',
        action: 'play',
        label: vidName,
      });
    } catch (e) {
      console.log('onVidPlay error:', e);
    }
  };

  changeText = () => {
    this.setState({
      blurbText: (
        <div>
          {!this.props.synced && !this.props.metamaskInstalled ? (
            <div>
              <div>
                <div className="splashBlurb" style={{ paddingTop: 100 }}>
                  To use the app's full functionality, download and connect
                  Metamask or download and use Status.
                </div>
                <div>
                  <a href="http://metamask.io">
                    <div className="metamaskImage">
                      <img src="/images/metamask.svg" alt="MetaMask" />
                    </div>
                    <div className="downloadMetamask">DOWNLOAD METAMASK</div>
                  </a>

                  <a href="https://status.im/get/">
                    <div className="statusImage">
                      <img src="/images/open-with-status.svg" alt="Status" />
                    </div>
                    <div className="downloadMetamask">DOWNLOAD STATUS</div>
                  </a>
                </div>
              </div>
            </div>
          ) : !this.props.synced && this.props.metamaskInstalled ? (
            <>
              <div className="splashBlurb" style={{ paddingTop: 100 }}>
                To use the app's full functionality, download and connect
                Metamask or download and use Status.
              </div>
              <div>
                <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask-Part-1-">
                  <div className="metamaskImage">
                    <img src="/images/metamask.svg" alt="MetaMask" />
                  </div>
                  <div className="downloadMetamask">GET METAMASK HELP</div>
                </a>

                <a href="https://status.im/faq/">
                  <div className="statusImage">
                    <img src="/images/connect-with-status.svg" alt="Status" />
                  </div>
                  <div className="downloadMetamask">GET STATUS HELP</div>
                </a>
              </div>
            </>
          ) : null}
        </div>
      ),
    });
  };
  metaMaskTransition = (e) => {
    gsap.to(document.getElementById('splashlogo'), {
      scale: 0.7,
      height: 80,
      y: 35,
      delay: 0,
      yoyo: true,
      duration: 1,
      ease: 'power3.out',
      repeat: 0,
    });
    gsap.to(document.getElementById('splashBlurbContainer'), {
      opacity: 0,
      duration: 1,
      delay: 0,
      yoyo: true,
      ease: 'power3.out',
      repeat: 1,
    });

    gsap.to(document.getElementById('splashBlurbContainer'), {
      y: 0,
      duration: 1,
      ease: 'power3.out',
      onComplete: this.changeText,
    });

    gsap.to(document.getElementById('splashButton'), {
      scale: 0.7,
      opacity: 0,

      duration: 1,
      pointerEvents: 'none',
      delay: 0,
      ease: 'power3.out',
    });
  };
  render() {
    const {
      synced,
      expenditures,
      plates,
      skipped,
      onhold,
      totalExpendedUsd,
      UI: {
        loadingLatest,
        loadingLeaderboard,
        loadingTotalExpenditure,
        loadingPlates,
        loadingOnhold,
      },
    } = this.props;

    const opts = {
      height: '220',
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    };

    return (
      <div>
        <div className="mainContent">
          {!synced && !skipped ? (
            <>
              <div
                className="splashGrid"
                id="splashgrid"
                style={{ height: `calc(100% - 40px)` }}
              >
                <div className="splashLogo" id="splashlogo">
                  <img src="/images/logo.png" alt="logo" />
                </div>

                <div
                  style={{ color: '#a1a1a1', paddingTop: 25 }}
                  className="splash-logo"
                >
                  A new standard in{' '}
                  <span className="yellow-text">transparency</span> for{' '}
                  <span className="yellow-text">charitable</span> causes.
                </div>
                <div
                  style={{
                    position: 'relative',
                    height: 'auto',
                    marginBottom: '20px',
                  }}
                  onClick={() => this.props.skipWallet()}
                >
                  <div className="skipText">
                    <div id="continue" className="skipLink">
                      Skip connecting my wallet
                    </div>
                    <div id="or">or</div>
                  </div>
                </div>

                {this.state.blurbText && <div>{this.state.blurbText}</div>}

                <div className="splashButtonContainer" id="splashButton">
                  <div
                    className="splashButton noSelect connectButton"
                    onClick={() => this.metaMaskTransition()}
                  >
                    Connect
                  </div>
                </div>

                <div className="splashEthereum2">
                  <p>Powered by</p>
                  <img src="/images/ethereum-white.png" alt="ethereum" />
                </div>
              </div>
            </>
          ) : null}

          {synced || skipped ? (
            <div>
              {!loadingLatest &&
              !loadingLeaderboard &&
              !loadingTotalExpenditure &&
              !loadingPlates &&
              !loadingOnhold ? (
                <>
                  {!localStorage.getItem('videoForget') ||
                  this.state.videoShow ? (
                    <div style={{ padding: '20px' }}>
                      <div className="howItWorks">
                        <div className="howItWorksHeader">
                          <h3>About Us</h3>
                          <div className="dontShow" onClick={this.removeVideo}>
                            <span>Don't show again</span>
                            <img src="images/dontShowIcon.svg" alt="x" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="howItWorksvideo">
                          <YouTube
                            videoId="BDgY7-fxO4E"
                            opts={opts}
                            onReady={this._onReady}
                            onPlay={() => this.onVidPlay('about-us')}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="loaderHolder">
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              {synced && (
                <div
                  className="donateButton noSelect"
                  style={{ marginTop: '50px' }}
                  onClick={() =>
                    this.props.menuSwitch('/donate', this.props.history)
                  }
                >
                  Donate
                </div>
              )}

              <div
                className="donatedAmounts dashboardelements"
                style={{ padding: '20px' }}
              >
                <div className="holdBox">
                  <h2 id="yellow">On hold</h2>
                  <div className="glowBox generic" id="yellow">
                    <span id="total">
                      {onhold &&
                        Number(onhold.contractBalance / 1e18).toFixed(3)}
                      <b>ETH</b>
                    </span>
                  </div>
                </div>
                <div className="deployedBox">
                  <h2 id="green">Deployed</h2>
                  <div className="glowBox generic" id="green">
                    <span id="total">
                      {expenditures &&
                        Number(expenditures.totalExpendedEth / 1e18).toFixed(3)}
                      <b>ETH</b>
                    </span>
                    {expenditures ? (
                      <span id="dollar">
                        {((totalExpendedUsd || 0) / 100).toLocaleString(
                          'en-US',
                          {
                            style: 'currency',
                            currency: 'USD',
                          }
                        )}
                      </span>
                    ) : null}

                    <img src="/images/foodIcon.svg" alt="Food" />
                    <div className="deployedTotals">
                      <b>{plates && plates.platesDeployed}</b> plates of food
                    </div>
                    <div className="deployedTotals">
                      <b>${plates && plates.pricePerPlateUsd}</b> per plate
                    </div>
                  </div>
                </div>
              </div>
              <div className="socialGrid" style={{ marginTop: '10px' }}>
                <div>
                  <a
                    href="https://www.facebook.com/Standardcharity"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/social_fb.svg" alt="Facebook" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/standardcharity/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/social_inst.svg" alt="Instagram" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <img src="images/social_yt.svg" alt="YouTube" />
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    UI: state.UI,
    account: state.wallet && state.wallet.account,
    plates: state.UI.plates,
    expenditures: state.UI.expenditures,
    onhold: state.UI.onhold,
    totalExpendedUsd: state.UI.totalExpendedUsd,
  };
};

const mapActionsToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Home));

const config = {
  env:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'production'
      : process.env.REACT_APP_NODE_ENV === 'local'
      ? 'local'
      : 'development',
  development: {
    contracts: {
      standardCharity: {
        address: '0xE873A8671A8A718523fe59ffA6919BCF2621FDF1',
      },
    },
    admin: {
      wallet: {
        address: '0x7D6c6B479b247f3DEC1eDfcC4fAf56c5Ff9A5F40',
      },
    },
    backend: {
      api: {
        url: 'https://api-dev.standardcharity.org/',
      },
    },
  },
  local: {
    contracts: {
      standardCharity: {
        address: '0xE873A8671A8A718523fe59ffA6919BCF2621FDF1',
      },
    },
    admin: {
      wallet: {
        address: '0x7D6c6B479b247f3DEC1eDfcC4fAf56c5Ff9A5F40',
      },
    },
    backend: {
      api: {
        url: 'http://localhost:3001/',
      },
    },
  },
  production: {
    contracts: {
      standardCharity: {
        address: '0x09894d222b8F4EB3C935BF92ADB7692f0f9Be41F',
      },
    },
    admin: {
      wallet: {
        address: '0x6A99d9D18e8C52E3Ba6482b6eF186eB296a308B8',
      },
    },
    backend: {
      api: {
        url: 'https://api.standardcharity.org/',
      },
    },
  },
};

export default config;

import { GET_WALLET, CLEAR_WALLET } from '../types';

const initialState = {
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WALLET:
      return {
        ...state,
        account: action.payload,
      };
    case CLEAR_WALLET:
      return {
        ...state,
        account: { wallet: null },
      };
    default:
      return state;
  }
}

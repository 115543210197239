import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

class Leaderboard extends Component {
  state = {};

  changeRoute = (route) => {
    this.props.history.push(route);
  };

  render() {
    const { latest, leaderboard } = this.props;

    return (
      <div>
        <div className="mainContent">
          <div className="latestDonation" style={{ padding: '20px' }}>
            <div className="leftHeader">
              <h2 id="yellow">Latest donation:</h2>
              <div className="timeStamp">
                {moment(latest && latest.timestamp * 1000)
                  .startOf('hour')
                  .fromNow()}
              </div>
            </div>
            <div className="glowBox generic" id="yellow">
              <span id="total">
                {latest &&
                  Number(latest.value / 1000000000000000000).toFixed(3)}{' '}
                ETH
              </span>
              <span id="key">{latest && latest.donator}</span>
            </div>
          </div>
          <div className="leaderBoard">
            <div className="leftHeader" id="indent">
              <h2 id="yellow">Top donors:</h2>
            </div>
            {leaderboard &&
              leaderboard.map((val, i) => {
                return (
                  <div className="leaderItem" key={i * Math.random()}>
                    <div>
                      <img
                        src={'/images/leader_' + (i + 1) + '.svg'}
                        alt={i + 1}
                      />
                    </div>
                    <div id="key">{val.donator.slice(0, 25)}...</div>
                    <div id="amount">
                      {Number(val.totalValue / 1e18).toFixed(3)} ETH
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="socialGrid" style={{ marginTop: '40px' }}>
            <div>
              <a
                href="https://www.facebook.com/Standardcharity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_fb.svg" alt="Facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/standardcharity/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/social_inst.svg" alt="Instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <img src="images/social_yt.svg" alt="YouTube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    UI: state.UI,
    latest: state.UI.latest,
    leaderboard: state.UI.leaderboard,
  };
};

const mapActionsToProps = (dispatch) => ({
  // clearErrors: ggg => dispatch(clearErrors(ggg)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(
  Leaderboard
);

import React from 'react';

class SocialFooter extends React.Component {
  render() {
    return (
      <div className="socialGrid" style={{ marginTop: '40px' }}>
        <div>
          <a
            href="https://www.facebook.com/Standardcharity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/social_fb.svg" alt="Facebook" />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/standardcharity/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/social_inst.svg" alt="Instagram" />
          </a>
        </div>
        <div>
          <a
            href="https://www.youtube.com/channel/UCX9PskL6CjcyBcJ6dj7L-HQ?disable_polymer=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <img src="images/social_yt.svg" alt="YouTube" />
          </a>
        </div>
      </div>
    );
  }
}

export default SocialFooter;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DateRangePicker } from 'rsuite';

import TransactionHistoryRow from '../components/TransactionHistoryRow';
import SocialFooter from '../components/SocialFooter';
import { getExpenditures } from '../redux/actions/actions';

class HowItWorks extends Component {
  constructor() {
    super();

    this.state = {
      datesSelected: false,
    };
  }

  onDatesSelected = (range) => {
    let startDate = Math.floor(new Date(range[0]).getTime() / 1000.0);
    let endDate = Math.floor(new Date(range[1]).getTime() / 1000.0);

    this.setState({ datesSelected: true });

    this.props.getExpenditures(startDate, endDate);
  };

  render() {
    const { datesSelected } = this.state;

    const { allExpenditures, loadingAllExpenditures } = this.props;

    return (
      <div>
        <div className="mainContent">
          <div className="howItWorks" id="howitworks">
            <div className="centerHeader">
              <h2>
                <span id="yellow">Transaction History</span>
              </h2>
            </div>
          </div>

          <div className="leaderBoard">
            <div className="leftHeader" id="indent">
              <h2 id="yellow">Charitable expenditures:</h2>
            </div>

            <div style={{ paddingBottom: 30 }}>
              <DateRangePicker
                showOneCalendar
                format="MMM D, YYYY"
                className="calDesign"
                onOk={(e) => this.onDatesSelected(e)}
                placeholder={
                  <div className="placeHolder_btn">
                    <img src="/images/selectArrows.svg" alt="calendar" />
                    Select Dates
                  </div>
                }
              />
            </div>

            {loadingAllExpenditures && (
              <div className="loaderHolder">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {!loadingAllExpenditures &&
              (!allExpenditures ||
                (Array.isArray(allExpenditures) &&
                  allExpenditures.length === 0)) && (
                <h2
                  style={{
                    textAlign: 'center',
                    color: '#d99f01',
                    paddingTop: 25,
                  }}
                >
                  No expenditures{' '}
                  {datesSelected ? 'during the selected period' : 'yet'}
                </h2>
              )}

            {!loadingAllExpenditures &&
              allExpenditures &&
              Array.isArray(allExpenditures) &&
              allExpenditures.map((expenditure) => {
                return (
                  <TransactionHistoryRow
                    expenditure={expenditure}
                    key={expenditure}
                  />
                );
              })}
          </div>

          <SocialFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    UI: state.UI,
    allExpenditures: state.UI.allExpenditures,
    loadingAllExpenditures: state.UI.loadingAllExpenditures,
  };
};

const mapActionsToProps = (dispatch) => ({
  getExpenditures: (startAtTimestamp, endAtTimestamp) =>
    dispatch(getExpenditures(startAtTimestamp, endAtTimestamp)),
});

export default compose(connect(mapStateToProps, mapActionsToProps))(HowItWorks);
